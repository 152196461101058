<template>
  <div v-if="switchList">
    <img class="banner d-lg-block d-none" src="~assets/images/img/productBanner.jpg"/>
    <div class="about container-fluid">
      <div class="row">
        <div class="col-12 col-lg-2" style="padding: 0">
          <switch-bar :switchList="switchList"
                      @switchItem="switchItem"
                      :activeIndex="Number(activeIndex)"/>
          <router-link to="/search" tag="div" class="search">
            <p>产品搜索</p>
            <img src="~assets/images/icon/search.png"/>
          </router-link>
        </div>
        <div class="col-12 col-lg-9 aboutRight">
          <div class="rightTitle">
            <div class="rightTitleValue d-lg-block d-flex align-items-center">
              <p class="rightTitleValueC">{{showTitle}}</p>
              <p class="rightTitleValueE">{{showTitleE}}</p>
            </div>
            <div class="rightTitleLinks d-none d-lg-flex">
              <p>当前位置：</p>
              <router-link class="link" to="/home" tag="p">首页</router-link>
              >
              <!--刷新页面并且更新模块为第一块-->
              <router-link class="link" :to="{
                path:switchList.link,
                query:{
                  index:0,
                  category_id:switchList && switchList.childList[0].path?switchList.childList[0].path :undefined
                }}" tag="p">{{switchList.text}}
              </router-link>
              >
              <p class="linkLast">{{showTitle}}</p>
            </div>
          </div>
          <!--内容块-->
          <div class="WapBoxPadding">
            <p class="appTitle">{{app.appIntro}}</p>
            <div class="appList" v-for="item in app.appList">
              <p class="appListTitle" v-if="app.appList.length>1">{{item.name}}</p>
              <div class="row">
                <div v-for="childItem in item.products" class="col-lg-4 col-md-4 col-6 appListItemCoat" @click="productDtl(childItem.product_id)">
                  <div class="appListItem">
                    <p class="appListItemText">{{childItem.name}}</p>
                    <p class="appListItemArrow">→</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--内容块 END-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import SwitchBar from "../../components/content/SwitchBar";
  import {getProductList} from 'network/product';

  export default {
    name: "IndustryApp",
    components: {SwitchBar},
    data() {
      return {
        //当前活跃的块下标
        activeIndex: 0,
        app: {
          appIntro: '',
          appList: null
        },
      }
    },
    methods: {
      switchItem(index, tid) {
        if (index != this.$route.query.index) {
          if (tid) {
            this.$router.push({
              path: this.$route.path,
              query: {
                index: index,
                category_id: tid
              }
            })
          } else {
            this.$router.push({
              path: this.$route.path,
              query: {
                index: index
              }
            })
          }
        }
      },
      getProductList() {
        this.tid = this.$route.query.category_id;
        getProductList(this.tid).then(res => {
          this.app.appList = res.data.right_category_3;
          this.app.appIntro = res.data.right_heard_category_2[0].description;
          // console.log(res.data.right_category_3);
        })
      },
    //  跳转详情页
      productDtl(product_id){
        if (this.$store.state.showProductDtl){
          this.$router.push('/productdetail/'+product_id)
        }
      },
    },
    computed: {
      switchList() {
        //从vuex中取出对应的数据 ==>store ==>index
        if (this.$store.state.navInfo[2].childList) {
          return this.$store.state.navInfo[2];
        }
      },
      // 当前活跃块的中文标题
      showTitle() {
        return this.switchList.childList[this.activeIndex].title || this.switchList.childList[this.activeIndex].name
      },
      // 当前活跃块的英文标题
      showTitleE() {
        return this.switchList.childList[this.activeIndex].titleE || this.switchList.childList[this.activeIndex].en_name
      },
    },
    created() {
      this.activeIndex = this.$route.query.index;
      this.getProductList();
    },
    watch: {
      '$route'() {
        this.activeIndex = this.$route.query.index;
        this.getProductList();
      }
    }
  }
</script>
<style scoped>
  /* 小屏幕（平板，大于等于 768px） */
  @media (max-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: .6rem 3%;
      border-bottom: 1px solid #e2e2e2;
    }

    .WapBoxPadding {
      padding: 0 3%;
    }
  }

  /* 中等屏幕（桌面显示器，大于等于 992px） */
  @media (min-width: 992px) {
    .rightTitle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: .35rem;
      border-bottom: 1px solid #e2e2e2;
    }

    .rightTitleValueC {
      margin-bottom: .2rem;
    }
  }
</style>
<style scoped>
  .search{
    display: flex;
    align-items: center;
    margin-top: .6rem;
    justify-content: center;
    padding: .4rem 0;
    cursor: pointer;
    background-image: url("~assets/images/img/productSearchButBGM.jpg");
    background-size: cover;
  }
  .search p{
    font-size: .4rem;
    color: #fff;
    margin-right: .3rem;
  }
  .search img{
    height: .4rem;
    width: auto;
  }
  .appTitle {
    font-size: .4rem;
    color: #a6a6a6;
  }

  .appList {
    margin-top: 1.1rem;
  }

  .appListTitle {
    margin-bottom: .6rem;
    padding-left: .6rem;
    border-left: 3px solid var(--red);
    color: #4c4c4c;
    font-size: .6rem;
  }

  .appListItemCoat {
    padding-right: .6rem;
  }

  .appListItem {
    display: flex;
    padding: .4rem .2rem;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    margin-bottom: .6rem;
    cursor: pointer;
    position: relative;
  }

  .appListItem:hover {
    background-color: var(--red);
  }

  .appListItem:hover .appListItemText {
    color: #fff;
    right: .7rem;
    transition: right .4s;
  }

  .appListItem:hover .appListItemArrow {
    opacity: 1;
    transition: opacity .4s;
  }

  .appListItemText {
    font-size: .4rem;
    color: #4c4c4c;
    position: relative;
    right: 0;
    transition: all .3s linear;
  }

  .appListItemArrow {
    font-size: .5rem;
    color: #fff;
    position: absolute;
    right: 1rem;
    opacity: 0;
    transition: all .3s linear;
  }


  .banner {
    width: 100%;
    height: auto;
  }

  .about {
    padding: 1.65rem var(--switchMarginX);
  }

  .rightTitle {
    margin-bottom: .55rem;
  }

  .rightTitleValueC {
    font-size: .6rem;
    color: #474747;
    margin-right: .4rem;
  }

  .rightTitleValueE {
    font-size: .35rem;
    color: #b2b2b2;
    padding: 0;
  }

  .rightTitleLinks {
    display: flex;
    align-items: center;
    font-size: .35rem;
    color: #b2b2b2;
    cursor: pointer;
  }

  .rightTitleLinks .link:hover {
    color: var(--red);
    transition: color .3s;
  }

  .rightTitleLinks .linkLast {
    color: #737373;
  }
</style>