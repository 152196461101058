import {request} from "./request";
export function getProductList(category_id) {
  return  request({
    params:{
      route:'product/category/getCategory',
      path:category_id
    },
  })
};export function getProductDtl(product_id) {
  return  request({
    params:{
      route:'product/product/getproductdetail',
      product_id
    },
  })
};
